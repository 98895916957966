import { useAuth0 } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import AppContext, { actions, initialState, State } from "../context/kwitantieContext"
import Loader from "../context/loader"
import { serverlessAuth } from "../utils/serverless"
import DeleteKwitantieButton from "./deleteKwitantieButton"
import Kwitantie from "./kwitantie"
import KwitantieActionHeader from "./kwitantieActionHeader"


const KwitantieItem = () => {
  const { state, dispatch }  = useContext(AppContext)

  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const searchString =
    typeof window !== "undefined" ? window.location.search : ""

  useEffect(() => {
    if (isAuthenticated) {
      const length = searchString.length
      if (length < 5) {
        navigate("/overview")
        return
      }
      const id = searchString.substring(4, length)
      if (id === "new") {
        serverlessAuth(
          "/api/NewKwitantie/",
          undefined,
          "GET",
          getAccessTokenSilently
        ).then(privouslyKwitantie => {
          const startValue = privouslyKwitantie?? initialState;
          console.log(startValue);
     
          dispatch({
            type: actions.load,
            payload: { ...startValue, isSaved: true }
          });

          if(privouslyKwitantie){
            dispatch({
              type: actions.reset           
            });      
          }
        });
      }
      else{
        serverlessAuth(
          "/api/GetKwitantie/?id=" + id,
          undefined,
          "GET",
          getAccessTokenSilently
        ).then(a => dispatch({
          type: actions.load,
          payload: { ...a, isSaved: true }
        }))
     }
    }
  }, [isAuthenticated, searchString])

  if (!state) {
    return (<Loader />)
  }

  return (
    <>
      <KwitantieActionHeader isHomepage={false} />
      <Kwitantie />
      <div style={{ textAlign: "center", paddingTop: "2rem" }}>
        <DeleteKwitantieButton />
      </div>
    </>);
}
export default KwitantieItem
