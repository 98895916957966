import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect } from "react"
import Loader from "./loader"

const Login = ({ children }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated) {
      window.sessionStorage.setItem("redirect", window.location.pathname)
      loginWithRedirect()
    }
  }, [isAuthenticated])

  if (typeof window === "undefined") {
    return <Loader />
  }
  return !isAuthenticated ? <Loader /> : <>{children}</>
}

export default Login
