import { useAuth0 } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import DeleteKwitantieButton from "../components/deleteKwitantieButton"
import Kwitantie from "../components/kwitantie"
import KwitantieActionHeader from "../components/kwitantieActionHeader"
import KwitantieItem from "../components/kwitantieItem"
import Layout from "../components/layout"
import { AppProvider, initialState, State } from "../context/kwitantieContext"
import Loader from "../context/loader"
import Login from "../context/login"
import { serverlessAuth } from "../utils/serverless"

const Item = () => {


  return (
    <Layout>
      <Login>
        <AppProvider initState={undefined}>
          <KwitantieItem/>
        </AppProvider>
      </Login>
    </Layout>
  )
}

export default Item
