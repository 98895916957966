import { useAuth0 } from "@auth0/auth0-react"
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { navigate } from "gatsby"
import React, { useCallback, useContext, useState } from "react"
import ProgressBar from "react-topbar-progress-indicator"
import AppContext, { State } from "../context/kwitantieContext"
import { serverless, serverlessAuth } from "../utils/serverless"

const DeleteKwitantieButton = () => {
  const context = useContext<any>(AppContext)
  const { getAccessTokenSilently } = useAuth0()
  const control = useDisclosure(false)
  const toast = useToast()
  const { state, dispatch }: { state: State; dispatch: any } = context
  const [isWorking, setIsWorking] = useState(false)
  const callback = useCallback(async () => {
    setIsWorking(true)

    serverlessAuth(
      "/api/DeleteKwitantie",
      { rowKey: state.rowKey },
      "POST",
      getAccessTokenSilently
    )
      .then(a => {
        navigate("/overview")

        setIsWorking(false)
        toast({
          title: "Kwitantie verwijderd",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(a => {
        toast({
          title: "Geen verbinding mogelijk met onze server",
          description: `Probeer het later opnieuw.`,
          status: "error",
          duration: 10000,
          isClosable: true,
        })
        setIsWorking(false)
      })
  }, [serverless, state])

  return (
    <>
      {isWorking && <ProgressBar />}
      <Button variantColor="red" onClick={control.onOpen}>
        Verwijder kwitantie
      </Button>

      <Modal isOpen={control.isOpen} onClose={control.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verwijderd kwitantie</ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button variant="ghost" onClick={control.onClose} mr={3}>
              Annuleer
            </Button>
            <Button
              variantColor="red"
              onClick={() => {
                callback()
                control.onClose()
              }}
            >
              Verwijder kwitantie
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default DeleteKwitantieButton
